import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-main-component',
  templateUrl: './main-component.component.html',
  styleUrls: ['./main-component.component.css']
})
export class MainComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {    
    // document.documentElement.style.setProperty('--primary-color', '#009530');
    // window.dispatchEvent(new Event('resize'));
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
      let evt:any = document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
     } else {
        window.dispatchEvent(new Event('resize'));
     }
    $('body').addClass('hold-transition skin-black sidebar-mini');
  }

}
