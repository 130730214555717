import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as shared from './../rw-config';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  private baseUrl: string;
  constructor(private router: Router) {
    this.baseUrl = shared.baseUrl;
   }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
      url:this.baseUrl+request.urlWithParams
    });
    if (request.method === 'GET') {
      request = request.clone({
        headers: request.headers.set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
      });
    }
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log("central error handling ", error);
          if(this.router.url.includes('/admin') && !request.headers.get("custom-error-handle")){
            this.router.navigate(['/admin/error-page', error.status]);
          }
          return throwError(error.message);
        })
      )
  }
}