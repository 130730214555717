import { Injectable } from '@angular/core';
import { CanLoad,  Route, Router } from '@angular/router';
import {AuthService} from './auth.service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad {

  constructor(private authService:AuthService, private router: Router) { }
  canLoad(route: Route) : Observable<boolean> | Promise<boolean> | boolean  {
    return this.authService.sessionStatus().toPromise().then((res:any)=>{
      // console.log("Can Load ",res);
        if(!res.Id){
          this.router.navigate['/auth'];
          return false;
        }
        return true;
      });
  }
}
