import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private http: HttpClient) {
  }

  login(credentialObj: any) {
    return this.http.post("login", credentialObj);
  }

  sessionStatus() {
    return this.http.get("login/check_session");
  }

  logout() {
    return this.http.get("login/clear_session");
  }

}
