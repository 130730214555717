import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from './../../../services/session/session.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

declare var $;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  menus = [];
  private sideMenuUrlMap;
  subscriptions: Subscription;

  constructor(private sessionService: SessionService, private router: Router) {
    this.sideMenuUrlMap = this.sessionService.sideMenuUrlMap;
  }

  ngOnInit(): void {
    let userOb: any = this.sessionService.getUser();
    this.menus = userOb.menus;
    this.subscriptions = new Subscription();

    $(document).ready(() => {
      $('.sidebar-menu').tree();
    });
    this.subscriptions.add(
      this.router.events.subscribe((data)=>{
        if(data instanceof NavigationEnd) {
          let url = data.url;
          if(url.indexOf("/manage/") > -1){
            url = url.slice(0,url.indexOf("/manage/"));
          }
          this.menus.forEach(menu => {
            menu.active = false;
            if (url == this.sideMenuUrlMap.get(menu.state)) {
              menu.active = true;
            }
            if (menu.submenus) {
              menu.submenus.forEach(submenu => {
                submenu.active = false;
                if (url == this.sideMenuUrlMap.get(submenu.state)) {
                  menu.active = true;
                  submenu.active = true;
                }
              });
            }
          })
        }
      })
    )

    let mainUrl = this.router.url
    if(mainUrl.indexOf("/manage/") > -1){
      mainUrl = mainUrl.slice(0,mainUrl.indexOf("/manage/"));
    }
    this.menus.forEach(menu => {
      menu.active = false;
      if (mainUrl == this.sideMenuUrlMap.get(menu.state)) {
        menu.active = true;
      }
      if (menu.submenus) {
        menu.submenus.forEach(submenu => {
          submenu.active = false;
          if (mainUrl == this.sideMenuUrlMap.get(submenu.state)) {
            menu.active = true;
            submenu.active = true;
          }
        });
      }
    })
  }

  goToMenu(state) {
    if (!state)
      return;
    // this.router.navigateByUrl(this.sideMenuUrlMap.get(state));
    console.log("goto menu")
    this.router.navigate([this.sideMenuUrlMap.get(state)]);

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
