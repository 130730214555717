<aside class="main-sidebar rw-hide-scroll-bar" style="max-height: 100%;overflow: scroll;background: white;">
    <section class="sidebar">
        <ul class="sidebar-menu" data-widget="tree">
            <li class="treeview" *ngFor="let menu of menus" [ngClass]="{'active':menu.active, 'menu-open':menu.active}">
                <a href="#" (click)="goToMenu(menu.state)">
                    <i class="{{menu.icon}}"></i>
                    <span style="text-transform: uppercase;">{{menu.name}}</span>
                    <span class="pull-right-container" *ngIf="menu.submenus && menu.submenus.length > 0">
                        <i class="fa fa-angle-right pull-right"></i>
                    </span>
                </a>
                <ul class="treeview-menu" *ngIf="menu.submenus && menu.submenus.length > 0" [ngStyle]="{display: menu.active?'block':'none'}">
                    <li *ngFor="let subMenu of menu.submenus" (click)="goToMenu(subMenu.state)" [ngClass]="{'active':subMenu.active}">
                        <a style="padding-left: 20px;">
                            <i class="{{subMenu.icon}}"></i>
                            <span style="text-transform: uppercase;cursor: pointer;" title="{{subMenu.title}}">{{subMenu.title}}</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </section>
</aside>