<header class="main-header">
    <a class="logo rwlink" (click)="goToFirstLink()">
        <span class="logo-mini"><b>A</b></span>
        <span class="logo-lg"><b>Admin</b></span>
    </a>
    <nav class="navbar navbar-static-top">
        <a class="sidebar-toggle" data-toggle="push-menu" href="#" role="button">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </a>
        <div class="navbar-custom-menu">
            <ul class="nav navbar-nav">
                <li class="dropdown user user-menu">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <img src="./../../../../assets/images/default_user.png"
                            style="max-width: 20px;margin-right:10px" class="img-circle" alt="{{userObj.Name}}" />
                        <span class="hidden-xs">{{userObj.Name}}</span>
                        <i class="fa fa-sort-desc" aria-hidden="true" style="margin-left: 5px;"></i>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="user-header" style="background-color: #FBFBFB;color: black;">
                            <img alt="{{userObj.Name}}" class="img-circle"
                                src="./../../../../assets/images/default_user.png">
                            <p style="color: black;">
                                {{userObj.Name}}
                            </p>
                        </li>
                        <li class="user-footer text-center">
                            <!-- <div class="pull-right">
                                <a class="btn gbtnPrimary" (click)="logOut()">Sign out</a>
                            </div> -->
                            <button class="gbtnPrimary" (click)="logOut()">
                                Logout
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</header>