import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {SessionService} from './../../../services/session/session.service';
import {AuthService} from '../../auth/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userObj:any;
  sideMenuUrlMap;
  constructor(private sessionService:SessionService,private authService:AuthService, private router: Router) { 
    this.sideMenuUrlMap = this.sessionService.sideMenuUrlMap;
  }

  goToFirstLink(){
    this.sessionService.goToFirstLink();
  }

  ngOnInit(): void {
    this.userObj = this.sessionService.getUser();
  }

  logOut(){
    this.authService.logout().subscribe(
      (res)=>{
        this.sessionService.clearUser();
        this.router.navigate(['/auth']);
      }
    );
  }

}
